/* eslint-disable @next/next/no-img-element */
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import MuiContainer from "@mui/material/Container";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme } from "@mui/material";

const Container = styled.div`
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  background-repeat: no-repeat;
  background-image: url(${require("../../../assets/images/TaustakuvaArtboard63.png?resize&size=1200&format=webp")
    .src});
  background-size: cover;
  background-position: center;
`;

const ContentContainer = styled(MuiContainer)`
  z-index: 1;
  position: relative;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const Footer = styled.div`
  padding-top: 32px;
  z-index: 2;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const FeedbackText = styled.div`
  line-height: 19pt;
  font-size: 12pt;
  font-weight: 300;
  text-align: center;
`;

/**
 * Section4
 * @returns {JSX.Element}
 */
const Section4 = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container>
      <ContentContainer>
        <Grid container rowSpacing={3} columnSpacing={8}>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: "center" }}
              variant="h2"
              component="div"
              gutterBottom
            >
              Koulutuspalautteita:
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FeedbackText>
              “Selkeäsanaista ja rehellistä opetusta. <br />
              Todella hyvä koulutus!”
            </FeedbackText>
          </Grid>
          <Grid item xs={12} md={6}>
            <FeedbackText>
              “Todella hyvä ja monipuolinen koulutus. Asiat olivat
              mielenkiintoisesti esitetty ja käytännön harjoitukset tukivat
              oppimista. Todella hyvä koulutus, ammattilaisia!“
            </FeedbackText>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginTop: 0 }}>
            <FeedbackText>
              “Koulutus oli kokonaisuudessaan loistava, asia kerrottiin hyvin
              ihmisläheisesti ja koulutuksessa olijat otettiin koko ajan
              huomioon. Kaikki asiat käytiin huolellisesti läpi, niin ettei
              kysyttävääkään jäänyt. Nostan hattua näille miehille, ovat ihan
              oikealla alalla.”
            </FeedbackText>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginTop: 0 }}>
            <FeedbackText>
              “Tämä oli paras turvakoulutus jossa olen ollut. Kouluttajien
              asiantuntemukseen oli helppo luottaa. Toivottavasti päästään
              jatkossakin tämän yrityksen koulutuksiin!”
            </FeedbackText>
          </Grid>
          <Grid item xs={12} md={6} sx={{ margin: "auto" }}>
            <FeedbackText>
              “Käytännön esimerkit ja hyvät videot konkretisoivat asioita.
              Kouluttajilla vahva asiantuntemus.”
            </FeedbackText>
          </Grid>
          <Grid item xs={12} md={6} sx={{ margin: "auto" }}>
            <FeedbackText>
              “Mukavat ja rennot kouluttajat. Osasivat asiansa ja tiesivät
              oikeasti mistä puhuu.“
            </FeedbackText>
          </Grid>
        </Grid>
      </ContentContainer>
      <Footer>
        {/*  <IconButton aria-label="down" style={{ border: '2px solid #fff' }}>
        <ArrowDownwardIcon htmlColor="#fff" />
      </IconButton> */}
      </Footer>
    </Container>
  );
};

export default Section4;
