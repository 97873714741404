import type { NextPage } from 'next'
import Head from 'next/head'
import Content from '../src/components/Content'
import Footer from '../src/components/Footer'
import TopBar from '../src/components/TopBar'
import FirstPageHeader from '../src/content/firstpage/Header'
import Section1 from '../src/content/firstpage/Section1'
import Section2 from '../src/content/firstpage/Section2'
import Section3 from '../src/content/firstpage/Section3'
import Section4 from '../src/content/firstpage/Section4'
import Section5 from '../src/content/firstpage/Section5'

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Safetycon - Etusivu</title>
      </Head>
      <TopBar />

      <Content>
        <FirstPageHeader />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </Content>

      <Footer />
    </>
  )
}

export default Home
