/* eslint-disable @next/next/no-img-element */
import styled from "@emotion/styled";
import MuiContainer from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Feedback from "./components/Feedback";
import { useMediaQuery, useTheme } from "@mui/material";

const Container = styled.div`
  position: relative;
  padding-top: 128px;
  padding-bottom: 128px;
  background-color: #fff;
`;

const ContentContainer = styled(MuiContainer)`
  z-index: 1;
  position: relative;
  color: #000;
`;

/**
 * Section 3
 * @returns {JSX.Element}
 */
const Section3 = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container>
      <ContentContainer>
        <Grid container spacing={8}>
          <Grid item xs={12} style={{ paddingTop: 30 }}>
            <Typography
              variant="h2"
              component="div"
              style={{
                maxWidth: matches ? "50%" : "90%",
                textAlign: "center",
                margin: "auto",
              }}
            >
              Mitä asiakkaamme meistä sanovat?
            </Typography>
          </Grid>
          <Feedback
            text="“Olemme todella tyytyväisiä Safetyconin kanssa tehtyyn yhteistyöhön. Yhteistyö erittäin sujuvaa, pelastusalan ammattilaiset isolla A:lla valmentamassa ymmärtäen meidän työn arjen, erittäin hyvät koulutusmateriaalit ja käytännön harjoitteet. Yhden osallistujan sanoin, vihdoinkin turvakoulutukset tällä vuosituhannella &#10084;&#65039;. Valmennuksista on tullut pelkkää kiitosta osallistujilta. Suosittelemme lämpimästi!”"
            name="Tuula Lyytikäinen"
            title="Henkilöstöjohtaja"
            company="Osuuskauppa Suur-Savo"
          />
          <Feedback
            text="”Yhteistyö Safetyconin kanssa on kitkatonta ja palvelun ostajalla on mahdollisuus vaikuttaa opintokokonaisuuteen ostajalle sopivalla tavalla, opintokokonaisuuden kuitenkaan kärsimättä.”"
            name="Arto Räsänen"
            title="Apulaisjohtaja"
            company="Mikkelin Vankila"
          />
        </Grid>
      </ContentContainer>
    </Container>
  );
};

export default Section3;
