import styled from "@emotion/styled";
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22pt;
  font-size: 16pt;
`;

const Signature = styled.div`
  font-size: 12pt;
  font-weight: 400;
  line-height: 19pt;
  text-align: center;
`;

interface FeedbackProps {
  text: string;
  name?: string;
  title?: string;
  company?: string;
}

/**
 * Feedback
 * @returns {JSX.Element}
 */
const Feedback = ({ text, name, title, company }: FeedbackProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid item xs={12} md={6}>
      <Container style={{ paddingLeft: matches ? "16px" : "0px" }}>
        <img
          style={{ margin: "auto", marginBottom: "2rem" }}
          src={require("../../../../assets/images/Lainausmerkki.png?resize&size=80&format=webp")}
          alt="Lainausmerkki"
          width={55}
          height={40}
        />
        <Signature style={{ padding: "0 20px 0 20px" }}>{text}</Signature>
        {/* Feedback info requires all these props. If there will be some kind of feedbacks, 
        where some of these are missing, it needs to be figured out in a different way. */}
        {name && title && company && (
          <Signature
            style={{ marginTop: "15px" }}
          >{`${name}, ${title} - ${company}`}</Signature>
        )}
      </Container>
    </Grid>
  );
};

export default Feedback;
