/* eslint-disable @next/next/no-img-element */
import styled from '@emotion/styled';
import MuiContainer from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React from 'react';

const Container = styled.div`
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  background-repeat: no-repeat;
  background-image: url(${require('../../../assets/images/Arvomme.png?resize&size=1200&format=webp').src});
  background-size: cover;
  background-position: center;
`;

const ContentContainer = styled(MuiContainer)`
  z-index: 1;
  position: relative;
  color: #fff;
  padding-top: 64px;
  padding-bottom: 64px;
`;

const Paper = styled.div`
  // background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #000;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  color: #fff;
  text-align: center;
`;

/**
 * Section2
 * @returns {JSX.Element}
 */
const Section2 = () => {
  return <Container>
    <ContentContainer>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h2" component="div" gutterBottom align="center">Arvomme</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={require('../../../assets/images/Ikonit/Tahdikkus_1.png?resize&size=100&format=webp')}
              alt="Tahdikkuus"
              width={100}
              height={100}
            />
            <Content>
              <Typography variant="h3" gutterBottom sx={{ marginBottom: '20px' }}>Tahdikkuus</Typography>
              <Typography variant="subtitle1">Tilannetajuinen ja harkintakykyinen henkilöstömme toimii ratkaisukeskeisesti ja tavoitteellisesti kaikissa tilanteissa.</Typography>
            </Content>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={require('../../../assets/images/Ikonit/Turvallisuus_1.png?resize&size=100&format=webp')}
              alt="Turvallisuus"
              width={100}
              height={100}
            />
            <Content>
              <Typography variant="h3" gutterBottom sx={{ marginBottom: '20px' }}>Turvallisuus</Typography>
              <Typography variant="subtitle1">Edistämme ihmisten ja yritysten turvallisuutta, innovatiivisia ratkaisuja hyödyntäen. Huolehdimme vakuutusturvasta ja tietoturvasta.</Typography>
            </Content>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={require('../../../assets/images/Ikonit/Luotettavuus_1.png?resize&size=100&format=webp')}
              alt="Luotettavuus"
              width={100}
              height={100}
            />
            <Content>
              <Typography variant="h3" gutterBottom sx={{ marginBottom: '20px' }}>Luotettavuus</Typography>
              <Typography variant="subtitle1">Hoidamme sovitut tehtävät ammattimaisesti ja sovittuna aikana. Salassapito- ja vaitiolovelvollisuus ovat meille itsestäänselvyyksiä.</Typography>
            </Content>
          </Paper>
        </Grid>
      </Grid>
    </ContentContainer>
  </Container>
}

export default Section2;