import styled from "@emotion/styled";
import MuiContainer from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/dist/client/router";

const Container = styled.div`
  position: relative;
  padding-top: 128px;
  padding-bottom: 128px;
  background-color: #fff;
`;

const ContentContainer = styled(MuiContainer)`
  z-index: 1;
  position: relative;
  color: #000;
`;

const Box1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  background-repeat: no-repeat;
  background-image: url(${require("../../../assets/images/Etusivun-taustakuva-kouluttajat.jpg?resize&size=1200&format=webp")
    .src});
  background-size: cover;
  background-position: center;
  color: #fff;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 128px;
  padding-bottom: 128px;
  height: 100%;
  text-align: center;
  position: relative;
`;

const Box2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  background-repeat: no-repeat;
  background-image: url(${require("../../../assets/images/Drone-Safetycon360.jpg?resize&size=1200&format=webp")
    .src});
  background-size: cover;
  background-position: center;
  color: #fff;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 128px;
  padding-bottom: 128px;
  height: 100%;
  text-align: center;
  position: relative;
`;

/**
 * Section 1
 * @returns {JSX.Element}
 */
const Section1 = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const router = useRouter();

  const handleRedirectToServices = (location: string) => () => {
    router.push(location);
  };

  return (
    <Container id="section1">
      <ContentContainer>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              component="div"
              gutterBottom
              align="center"
            >
              Mitä me teemme?
            </Typography>

            <div
              style={{
                ...(matches && { display: "flex", justifyContent: "center" }),
              }}
            >
              <Typography
                variant="subtitle1"
                component="div"
                align="center"
                style={{ ...(matches && { maxWidth: "70%" }) }}
              >
                Tuomme ennakkoluulottomasti nykyaikaisia turvallisuuden
                kehittämiseen ja riskienhallintaan liittyviä ratkaisuja,
                räätälöityjä koulutuksia sekä lupakoulutuksia yrityksille ja
                julkiselle sektorille.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box1>
              <Typography variant="h2" component="div">
                Turvallisuus-koulutukset
              </Typography>
              <Button
                onClick={handleRedirectToServices("koulutukset")}
                variant="contained"
                color="secondary"
                sx={{
                  borderRadius: "30px",
                  width: "270px",
                  height: "50px",
                  padding: "8pt",
                  mt: "20pt",
                  fontFamily: "Montserrat",
                  fontSize: "12pt",
                }}
                style={{ position: "absolute", bottom: "32px" }}
              >
                Lue lisää
              </Button>
            </Box1>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box2>
              <Typography variant="h2" component="div">
                Safetycon360
              </Typography>
              <Button
                onClick={handleRedirectToServices("safetycon360")}
                variant="contained"
                color="secondary"
                style={{ position: "absolute", bottom: "32px" }}
                sx={{
                  borderRadius: "30px",
                  width: "270px",
                  height: "50px",
                  padding: "8pt",
                  mt: "20pt",
                  fontFamily: "Montserrat",
                  fontSize: "12pt",
                }}
              >
                Lue lisää
              </Button>
            </Box2>
          </Grid>
        </Grid>
      </ContentContainer>
    </Container>
  );
};

export default Section1;
