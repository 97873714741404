import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import MuiContainer from "@mui/material/Container";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useCallback } from "react";
import { keyframes } from "@emotion/react";

const Container = styled.div`
  position: relative;
  padding-top: 128px;
  padding-bottom: 32px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const ContentContainer = styled(MuiContainer)`
  z-index: 1;
  position: static;
  color: #fff;
  padding-top: 64px;
  padding-bottom: 64px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

const Footer = styled.div`
  padding-top: 173px;
  z-index: 2;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-top: 32px;
  }
`;

const ImageAnimation = keyframes`
    0% { opacity: 1; }
    5% { opacity: 0; }
    15% { opacity: 0; }
    20% { opacity: 0; }
    50% { opacity: 0; }
    70% { opacity: 0; }
    75% { opacity: 1; }
    100% { opacity: 1; }
  `;

const Image1 = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -2;
  animation-name: ${ImageAnimation};
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  background-color: #000;
`;

const Image2 = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -2;
  animation-name: ${ImageAnimation};
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -4s;
  background-color: #000;
`;

const Image3 = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -2;
  animation-name: ${ImageAnimation};
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -8s;
  background-color: #000;
`;

const Image4 = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -2;
  animation-name: ${ImageAnimation};
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -12s;
  background-color: #000;
`;

/**
 * First page header
 * @returns {JSX.Element}
 */
const FirstPageHeader = () => {
  const handleOnClick = useCallback(() => {
    if (document) {
      document
        .getElementById("section1")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Container id="main-container">
      <ContentContainer>
        <Image1
          src={`${require("../../../assets/images/Etusivu-taustakuva-Drone-tinyplanet.jpg?resize&size=1200&format=webp")}`}
          id="f4"
          alt="Drone"
        />
        <Image2
          src={`${require("../../../assets/images/Etusivu-taustakuva-turvakävely.jpg?resize&size=1200&format=webp")}`}
          id="f3"
          alt="Turvakävely"
        />
        <Image3
          src={`${require("../../../assets/images/Etusivu-taustakuva-ensiapu.jpg?resize&size=1200&format=webp")}`}
          id="f2"
          alt="Ensiapu"
        />
        <Image4
          src={`${require("../../../assets/images/Etusivu-taustakuva-VR-mies.jpg?resize&size=1200&format=webp")}`}
          id="f1"
          alt="Vr-mies"
        />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ display: { xs: "block", sm: "none" } }}
              variant="h1"
              component="div"
              gutterBottom
            >
              Turvallisuus-kouluttamisen edelläkävijä
            </Typography>
            <Typography
              sx={{ display: { xs: "none", sm: "block" } }}
              variant="h1"
              component="div"
              gutterBottom
            >
              Turvallisuuskouluttamisen edelläkävijä
            </Typography>
            <Typography variant="subtitle1" component="div" gutterBottom>
              Safetycon Oy yhdistää laajan turvallisuusalan osaamisen,
              pedagogiikan ja virtuaalitodellisuuden. Tarjoamme
              valtakunnallisesti innostavaa koulutusta ja edistyksellisiä
              ratkaisuja asiakkaidemme turvallisuuden kehittämiseen.
            </Typography>
          </Grid>
        </Grid>
      </ContentContainer>
      <Footer>
        <IconButton
          aria-label="down"
          style={{ border: "2px solid #fff" }}
          onClick={handleOnClick}
        >
          <ArrowDownwardIcon htmlColor="#fff" />
        </IconButton>
      </Footer>
    </Container>
  );
};

export default FirstPageHeader;
