/* eslint-disable @next/next/no-img-element */
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import MuiContainer from "@mui/material/Container";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Container = styled.div`
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #fff;
`;

const ContentContainer = styled(MuiContainer)`
  z-index: 1;
  position: relative;
  color: #000;
  padding-top: 64px;
  padding-bottom: 64px;
`;

/**
 * Section4
 * @returns {JSX.Element}
 */
const Section5 = () => {
  return (
    <Container>
      <ContentContainer>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" component="div" gutterBottom>
              Yhteistyökumppanit
            </Typography>
            <Typography variant="subtitle1" component="div" gutterBottom>
              Meihin luottavat yhteistyökumppanimme.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid
              container
              spacing={6}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/S_SUURSAVO.png?resize&size=150&format=webp")}
                  alt="Suur-Savo logo"
                  width={150}
                  height={49}
                />
              </Grid>

              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/rise-logo.jpg?resize&size=150&format=webp")}
                  alt="Rise logo"
                  width={150}
                  height={150}
                />
              </Grid>

              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/jalon-logo.png?resize&size=150&format=webp")}
                  alt="Jalon logo"
                  width={150}
                  height={98}
                />
              </Grid>

              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/transval-logo.png?resize&size=150&format=webp")}
                  alt="Transval logo"
                  width={150}
                  height={84}
                />
              </Grid>

              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/ese-logo.png?resize&size=150&format=webp")}
                  alt="Ese logo"
                  width={150}
                  height={50}
                />
              </Grid>

              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/proxion-logo.png?resize&size=100&format=webp")}
                  alt="Proxion logo"
                  width={100}
                  height={22}
                />
              </Grid>

              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/juva-logo.jpg?resize&size=150&format=webp")}
                  alt="Juva logo"
                  width={140}
                  height={50}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/eekoo-logo.png?resize&size=150&format=webp")}
                  alt="Eekoo logo"
                  width={150}
                  height={60}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/espl-logo.png?resize&size=150&format=webp")}
                  alt="Espl logo"
                  width={160}
                  height={50}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/kso-logo.png?resize&size=150&format=webp")}
                  alt="Kso logo"
                  width={130}
                  height={65}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/lujatalo-logo.png?resize&size=150&format=webp")}
                  alt="Lujatalo logo"
                  width={160}
                  height={45}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/mikkeli-logo.gif?resize&size=150&format=webp")}
                  alt="Mikkeli logo"
                  width={140}
                  height={130}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/misawa-logo.png?resize&size=150&format=webp")}
                  alt="Misawa logo"
                  width={160}
                  height={45}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/paukkula-logo.png?resize&size=150&format=webp")}
                  alt="Paukkula logo"
                  width={160}
                  height={55}
                />
              </Grid>
              <Grid item xs="auto">
                <img
                  src={require("../../../assets/images/yrityslogot/peeassa-logo.png?resize&size=150&format=webp")}
                  alt="Peeässä logo"
                  width={200}
                  height={100}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </Container>
  );
};

export default Section5;
